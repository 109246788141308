<template>
  <div id="lav-lom-status-list">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ YearName }} - {{ PageTitle }}</h2>
        <v-spacer />
        <v-btn
          medium
          @click.prevent="refreshPageData"
          color="#a4c639"
          elevation="30"
          rounded
          class="font-size-h6 mr-3 my-3 white--text"
          >&nbsp; Refresh &nbsp;
        </v-btn>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
        <!-- <v-icon icon size="25px" color="primary">
          mdi-account-multiple-plus
        </v-icon>-->
      </v-card-subtitle>

      <v-container class="py-0">
        <v-speed-dial
          v-if="FloatingButtonFlag"
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
          :fixed="true"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab>
              <v-icon v-if="fab">mdi-close</v-icon>
              <v-icon v-else>mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
          </v-btn>-->
          <v-btn
            fab
            dark
            small
            color="indigo"
            @click.prevent="addRecordPrompt = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
          </v-btn>-->
        </v-speed-dial>
      </v-container>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0" v-if="StartupLoadingFlag">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-container>
        <v-container class="py-0" v-if="tableData1 <= 0">
          <v-row wrap v-if="tableData2.length == 0">
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap align="center">
            <v-col align="center" cols="12" sm="126" lg="4" md="4">
              <v-card dark hover color="#118ab2">
                <v-card-title class="card-title">
                  LAV Reporting Deadline
                </v-card-title>
                <v-card-subtitle>
                  <br /><br />
                  <h2 class="card-subtitle">
                    {{ TrainingData1.EndDate }}
                  </h2>
                </v-card-subtitle>
                <v-card-actions> </v-card-actions>
              </v-card>
            </v-col>
            <v-col align="center" cols="12" sm="6" lg="4" md="4">
              <v-card dark hover color="#06d6a0">
                <v-card-title class="card-title">
                  Reports approved for
                </v-card-title>
                <v-card-subtitle>
                  <h2 class="card-subtitle">
                    {{ TrainingData1.CompletedCount }} of
                    {{ TrainingData1.TotalCount }}
                    <span class="card-subtext">LOs</span>
                  </h2>
                  <br />
                </v-card-subtitle>
                <v-card-actions> </v-card-actions>
              </v-card>
            </v-col>
            <v-col align="center" cols="12" sm="6" lg="4" md="4">
              <v-card dark hover color="#ffd166">
                <v-card-title class="card-title">
                  LAV Reported for
                </v-card-title>
                <v-card-subtitle>
                  <br /><br />
                  <h2 class="card-subtitle">
                    {{ TrainingData1.PendingCount }} of
                    {{ TrainingData1.TotalCount }}
                    <span class="card-subtext">LOs</span>
                  </h2>
                </v-card-subtitle>
                <v-card-actions> </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <br />
          <hr />
          <br />
          <v-row wrap v-if="tableData1.length > 0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  v-if="DownloadFlag"
                  color="info"
                  @click.prevent="
                    generateExcel(
                      tableData1,
                      ExcelFields,
                      ExcelFileName,
                      'My Worksheet'
                    )
                  "
                  class="mr-4"
                  >Download</v-btn
                >
              </v-col>
              <p></p>
            </v-row>
            <v-col align="center" cols="12" md="12">
              <v-data-iterator
                :items="tableData1"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:header> </template>

                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="item in props.items"
                      :key="item.YearwiseLomId"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="4"
                    >
                      <v-card id="card" color="#073b4c" height="275px">
                        <v-card-text class="text-left">
                          <v-row wrap>
                            <v-col>
                              <h2 class="white--text">
                                {{ item.LomName }}
                              </h2>
                              <hr />
                              <span class="text-h5 white--text">
                                Approval Status:</span
                              >&nbsp;&nbsp; &nbsp;
                              <v-chip
                                :color="item.TrainingStatusColor"
                                draggable
                                dark
                                >{{ item.TrainingStatusTxt }}</v-chip
                              >
                              <br /><br />
                              <span class="text-h5 white--text">
                                LAV Status:</span
                              >&nbsp;&nbsp; &nbsp;
                              <v-chip
                                :color="item.CompletedStatusColor"
                                draggable
                                dark
                                >{{ item.CompletedStatusTxt }}</v-chip
                              >
                              <br /><br />
                              <span
                                class="text-h5 white--text"
                                v-if="item.CompletedStatus == 1 && CompletedDateFlag"
                              >
                                Completed Date: {{ item.CompletedDateTxt }}
                              </span>
                            </v-col>
                          </v-row>
                          <v-row align="center" justify="center">
                            <v-col v-if="item.CreateFlag">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-if="item.CreateFlag"
                                    @click="addData(item)"
                                    target="_blank"
                                    color="primary"
                                    small
                                    class="text-white"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon dark>mdi-upload</v-icon>Send Report
                                  </v-btn>
                                </template>
                                <span>Send {{ item.LomName }} LAV</span>
                              </v-tooltip>
                            </v-col>
                            <v-col v-if="item.EditFlag">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-if="item.EditFlag"
                                    @click="editData(item)"
                                    target="_blank"
                                    color="warning"
                                    small
                                    class="text-white"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon dark>mdi-pencil</v-icon>Edit Report
                                  </v-btn>
                                </template>
                                <span>Edit {{ item.LomName }} LAV</span>
                              </v-tooltip>
                            </v-col>
                            <v-col v-if="item.PreviewFlag">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-if="item.PreviewFlag"
                                    @click="previewData(index, item)"
                                    target="_blank"
                                    color="blue"
                                    small
                                    class="text-white"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon dark>mdi-file-search</v-icon>Preview
                                  </v-btn>
                                </template>
                                <span>{{ item.LomName }} LAV Preview</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-container>
        <br />
        <br />
      </v-card-text>
    </v-card>
    <v-dialog v-model="previewRecordPrompt" max-width="75%">
      <lom-training-preview
        pageDescription=" Training Details"
        category="1"
        :showDialog="previewRecordPrompt"
        @hideDialog="hidePreviewRecordPrompt"
        :row="selectedData"
        v-if="previewRecordPrompt"
      ></lom-training-preview>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import LomTrainingPreview from "@/view/pages/erp/ldmt/LomTrainingPreview.vue";

export default {
  mixins: [common],
  components: { LomTrainingPreview },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      FloatingButtonFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      TrainingData1: {},

      rows: [],
      tableColumns1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      CompletedDateFlag: false,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      selectedData: {},

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      TableDisplayFlag1: false,

      zone: {},
      lom: {},

      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      select: "Item 1",
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,

      JciYearCode: "",

      search1: "",
      selected1: "",
      tableColumns1: [],
      tableData1: [],
      tableOptions1: {},

      tableColumns2: [],
      tableData2: [],
      tableOptions2: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        this.pageData();
      }
    },
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lav",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.searchForm();
    },
    addData(tr) {
      console.log("addData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var url = "/lav/send-report";
        var LomCode = tr.LomId;
        console.log({ LomCode });
        var params = {
          v1: LomCode,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one LO to send report";
        this.sweetAlert("error", message, false);
      }
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var url = "/lav/edit-report";
        var LomCode = tr.LomId;
        console.log({ LomCode });

        var TrainingId = tr.LomTrainingId;
        console.log({ TrainingId });

        var CategoryId = tr.TrainingCategoryId;
        console.log({ CategoryId });

        var params = {
          v1: CategoryId,
          v2: LomCode,
          v3: TrainingId,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one LO to send report";
        this.sweetAlert("error", message, false);
      }
    },
    resetForm() {
      this.search = "";
      this.tableData2 = [];
      this.tableData1 = [];
      this.selected = [];
      this.$refs.form2.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewData(index, tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    searchForm() {
      console.log("searchForm called");

      var LomCode = this.$session.get("LomId");
      LomCode = LomCode == (null || undefined) ? 0 : LomCode;
      console.log("LomCode=" + LomCode);

      // var validate1 = this.$refs.form2.validate();
      var validate1 = true;
      console.log("validate1=" + validate1);

      if (validate1) {
        this.TrainingData = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-training/report";
        var upload = {
          UserInterface: 3,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.tableData1 = [];
        this.LoadingFlag = true;
        this.SearchFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            var options = records.TableOptions;
            thisIns.tableOptions1 = options;

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableData1 = records.TableData;
              thisIns.TrainingData1 = records.ImportantLavDates;

              thisIns.DownloadFlag = options.DownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;

              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill required fields.";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
    this.tableColumns1.push(
      { text: "Paid Date", value: "PaidDateTxt" },
      { text: "Year", value: "YearName" },
      {
        text: "Subscription Description",
        value: "ContributionTypeDescription",
      },
      { text: "Paid Count", value: "PaidCount" },
      { text: "Remaining Count", value: "RemainingCount" },
      { text: "Add members", value: "actions" }
    );
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

@import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";

#lav-lom-status-list {
  .card-title {
    font-size: 25px;
    font-family: "Lora", serif;
    padding: 20px;
    text-align: center;
    word-wrap: break-word;
  }
  .card-subtitle {
    font-size: 60px;
    font-family: "Lobster", cursive;
    color: white;
    text-align: center;
  }
  .card-subtext {
    font-size: 40px;
    font-family: "Roboto", cursive !important;
    color: white;
    text-align: center;
  }
}
</style>